import { useStaticQuery, graphql } from "gatsby"

export const useGeneralSchemaQuery = () => {
  const {
    wpgraphql,
    site,
    query1,
    forRatingValuePart1,
    forRatingValuePart2,
  } = useStaticQuery(
    graphql`
      query GeneralSchemaQuery {
        wpgraphql {
          nexvelSchemaMarkup {
            nexvelschema {
              businessType
              businessName
              logo {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fixed {
                      src
                    }
                  }
                }
              }
              image {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fixed {
                      src
                    }
                  }
                }
              }
              describeYourBusiness
              phoneNumber
              streetAddress
              city
              state
              zipCode
              hoursOfOperation {
                monday {
                  areYouOpen
                  open {
                    openHour
                    openMinute
                    amorpm
                  }
                  close {
                    closeHour
                    closeMinute
                    amorpm
                  }
                }
                tuesday {
                  areYouOpen
                  open {
                    openHour
                    openMinute
                    amorpm
                  }
                  close {
                    closeHour
                    closeMinute
                    amorpm
                  }
                }
                wednesday {
                  areYouOpen
                  open {
                    openHour
                    openMinute
                    amorpm
                  }
                  close {
                    closeHour
                    closeMinute
                    amorpm
                  }
                }
                thursday {
                  areYouOpen
                  open {
                    openHour
                    openMinute
                    amorpm
                  }
                  close {
                    closeHour
                    closeMinute
                    amorpm
                  }
                }
                friday {
                  areYouOpen
                  open {
                    openHour
                    openMinute
                    amorpm
                  }
                  close {
                    closeHour
                    closeMinute
                    amorpm
                  }
                }
                saturday {
                  open {
                    openHour
                    openMinute
                    amorpm
                  }
                  close {
                    closeHour
                    closeMinute
                    amorpm
                  }
                  areYouOpen
                }
                sunday {
                  areYouOpen
                  open {
                    openHour
                    openMinute
                    amorpm
                  }
                  close {
                    closeHour
                    closeMinute
                    amorpm
                  }
                }
              }
              offersSchema {
                offerSchema
                urlSchema
              }
              awardsSchema {
                awardSchema
              }
              brandsSchema {
                brandSchema
              }
              certificationsSchema {
                certificationSchema
              }
              languagesSchema {
                languageSchema
              }
              organizationsSchema {
                organizationSchema
              }
              socialMediaProfilesAndDirectoryListingsSchema {
                socialMediaProfileOrDirectoryListingSchema
              }
              services {
                service
                linkToRelevantPageOnYourWebsite
              }
              cities {
                city
              }
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
        query1: allBirdeyedata(
          filter: {
            id: { ne: "dummy" }
            sourceType: { nin: ["Direct Feedback", "Facebook"] }
          }
        ) {
          nodes {
            id
            sourceType
            reviewDate
            rating
            reviewer {
              firstName
              lastName
            }
            comments
            businessName
          }
        }
        forRatingValuePart1: allBirdeyedata(
          filter: { id: { ne: "dummy" }, sourceType: { ne: "Direct Feedback" } }
        ) {
          nodes {
            rating
          }
        }
        forRatingValuePart2: allBirdeyedata(
          filter: { id: { ne: "dummy" }, rating: { ne: 0 } }
        ) {
          nodes {
            rating
          }
        }
      }
    `
  )
  return { wpgraphql, site, query1, forRatingValuePart1, forRatingValuePart2 }
}
